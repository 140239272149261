import { batch } from "solid-js";

import Admin, { AdminCanada } from "@repo/models-kikoff/Admin";
import canadaAdminRpc from "@repo/protobuf/api/canadaAdminRpc";
import UFunction from "@repo/utils/UFunction";
import FeatureStore from "@repo/utils-solid/FeatureStore";

const initialState = {
  authenticated: null as boolean | null,
  admin: null as Admin | null,
};

export const { Provider, useAdmin } = FeatureStore.init(
  "Admin",
  initialState,
  ([store, setStore], { withLogger }) => {
    const accessors = {
      authenticated: () => store.authenticated,
    };

    const actions = withLogger("action", {
      googleSsoCallback(credential: string) {
        return canadaAdminRpc.Auth.googleSsoCallback({ credential }).then(
          ({ admin }) => {
            setStore("admin", AdminCanada.normalize(admin!));
            setStore("authenticated", true);
          }
        );
      },
      fetch() {
        return canadaAdminRpc.Auth.showCurrentAdmin({})
          .then(({ admin }) => {
            batch(() => {
              setStore("admin", AdminCanada.normalize(admin!));
              setStore("authenticated", true);
            });
          })
          .catch((e) => {
            setStore("authenticated", false);

            // TODO: UNAUTHENTICATED error code
            if (e.issues?.[0]?.code !== "UNKNOWN") throw e;
          });
      },
      logout: UFunction.todo,
    });

    return { accessors, actions };
  }
);
