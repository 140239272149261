import { Admin as CanadaAdminPb } from "@repo/protobuf/gen/kikoff_canada/protobuf/models/admin_pb";
import kikoffAdminApi from "@repo/rest/kikoffAdminApi";
import RestApi from "@repo/rest/RestApi";
import Case from "@repo/utils/Case";
import Enum from "@repo/utils/Enum";

type Admin = {
  id: Admin.Id;
  email: string;
  role?: "sysadmin" | "developer" | "customerSupport" | "galileo";
  status: "active" | "disabled";
};

export namespace Admin {
  export type Id = `a_${string}`;
}

export default Admin;

export namespace AdminCanada {
  export function normalize(admin: CanadaAdminPb): Admin {
    const role = Case.fromConstant(
      Enum.keyOf(CanadaAdminPb.Role, admin.role)
    ).toCamel();
    const status = Case.fromConstant(
      Enum.keyOf(CanadaAdminPb.Status, admin.status)
    ).toCamel();
    if (status === "unknown") throw new Error("Admin with status UNKNOWN");

    return {
      // TODO: Pass id from backend
      id: `a_${0}`,
      ...admin,
      role: role === "none" ? undefined : role,
      status,
    };
  }
}

export namespace AdminUsa {
  export function normalize(
    admin: RestApi.ResponseOf<(typeof kikoffAdminApi)["admin-info"]["GET"]>
  ): Admin {
    return {
      id: `a_${admin.id}`,
      email: admin.email,
      role: ({ admin: "sysadmin", galileo: "galileo" } as const)[admin.role!],
      status: "active",
    };
  }
}
